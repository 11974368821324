.title {
  max-height: 90%;
}

.title-text {
  font-family: "Posterboy ITC Std";
  font-size: 3.5rem;
  color:#0EA57C;
  text-shadow:-1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
  text-shadow:-2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  text-shadow:-5px 0 #fff, 0 5px #fff, 5px 0 #fff, 0 -5px #fff;
}

.title-image {
  width:20rem;
  height: auto;
}

.App {
  text-align: center;
  background-color: #F3F1DA;
}

@font-face {
  font-family: "Posterboy ITC Std"; 
  src: url("//db.onlinewebfonts.com/t/2e3e3e3c12d6575bb12cbf2ef85e3bb6.eot"); 
  src: url("//db.onlinewebfonts.com/t/2e3e3e3c12d6575bb12cbf2ef85e3bb6.eot?#iefix") format("embedded-opentype"),
   url("//db.onlinewebfonts.com/t/2e3e3e3c12d6575bb12cbf2ef85e3bb6.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/2e3e3e3c12d6575bb12cbf2ef85e3bb6.woff") format("woff"),
     url("//db.onlinewebfonts.com/t/2e3e3e3c12d6575bb12cbf2ef85e3bb6.ttf") format("truetype"),
      url("//db.onlinewebfonts.com/t/2e3e3e3c12d6575bb12cbf2ef85e3bb6.svg#Posterboy ITC Std") format("svg"); 
}